import React from 'react'

import { Links } from './container'

import './App.scss'

const Link = () => {
  return (
    <div className='app'>

        <Links />

    </div>
  )
}

export default Link